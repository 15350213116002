import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircleImage, Modal, Spacing, Text } from '@reservamos/elements';
import { trackEvent } from 'user-analytics';
import { CurrentLang } from 'utils/lang';
import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import useInactivity from '../hooks/useInactivity';

/**
 * PromotionalModal component. Displays a marketing modal after a period of user inactivity.
 *
 * @param {object} props - page.
 * @param {string} props.page - Page type for tracking events.
 */
const PromotionalModal = ({ page }) => {
  const currentLang = CurrentLang();
  const { ads } = useWhitelabelCopies() || {};
  const timeoutModal = ads?.timeoutModal || null;
  const pageData = timeoutModal?.[page] || null;
  const isIdle = useInactivity(pageData?.timeout || 10);
  const pageTitle = page ? page.charAt(0).toUpperCase() + page.slice(1) : '';
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isIdle && pageData) {
      setShowModal(true);
      trackEvent('Touchpoint Printed', {
        Page: pageTitle,
        Provider: pageData.provider || 'Unknown',
        Touchpoint: 'Promotional Modal',
        Category: pageData.category || 'Unknown',
      });
    }
  }, [isIdle, page, pageTitle, pageData]);

  /**
   * Handles click on the promotional CTA button
   *
   * @param {Event} e - Click event object
   */
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      trackEvent('Touchpoint Clicked', {
        Page: pageTitle,
        Provider: pageData.provider || 'Unknown',
        Touchpoint: 'Promotional Modal',
        Category: pageData.category || 'Unknown',
      });
      if (pageData.href) {
        window.open(pageData.href, '_blank');
      }
      setShowModal(false);
    },
    [pageData, pageTitle],
  );

  if (!page || !pageData) return null;

  const langData = pageData[currentLang] || pageData['es-MX'] || {};

  return (
    <>
      {showModal && (
        <Modal closeIcon="ClosePrimary" responsiveSize="M" onClose={() => setShowModal(false)}>
          <Spacing vertical alignItems="center">
            {pageData.src && <CircleImage photoUrl={pageData.src} />}
            <Text weight="bold" textAlign="center" size="L">
              {langData.title || ''}
            </Text>
            <Text textAlign="center">{langData.message || ''}</Text>
            <Button
              text={langData.cta || 'Continue'}
              variant="accent"
              fullWidth={false}
              onClick={handleClick}
            />
          </Spacing>
        </Modal>
      )}
    </>
  );
};

PromotionalModal.propTypes = {
  page: PropTypes.string.isRequired,
};

export default PromotionalModal;
