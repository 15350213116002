import { useEffect, useState } from 'react';

/**
 * useInactivity hook tracks user inactivity and returns idle state.
 *
 * @param {number} [idleTime=5000] - Time in seconds before user is considered idle
 * @returns {boolean} isIdle - Whether the user is currently idle
 *
 * @example
 * const isIdle = useInactivity(10); // User will be idle after 10 seconds
 * if (isIdle) {
 *   // Handle idle state
 * }
 */
const useInactivity = (idleTime = 10) => {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let timeout = null;

    /**
     * Clears the existing timeout and creates a new one to track inactivity
     */
    const restartTimer = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIsIdle(true), idleTime * 1000);
    };

    restartTimer();
    window.addEventListener('mousemove', restartTimer);
    window.addEventListener('keydown', restartTimer);
    window.addEventListener('touchstart', restartTimer);
    window.addEventListener('scroll', restartTimer);
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', restartTimer);
      window.removeEventListener('keydown', restartTimer);
      window.removeEventListener('touchstart', restartTimer);
      window.removeEventListener('scroll', restartTimer);
    };
  }, [idleTime]);

  return isIdle;
};

export default useInactivity;
