import useWhitelabelCopies from 'hooks/whitelabel/useWhitelabelCopies';
import React from 'react';
import { PromotionalBanner } from '@reservamos/elements';
import { CurrentLang } from 'utils/lang';
import PropTypes from 'prop-types';
import { trackEvent } from 'user-analytics';

/**
 * AdBanner component displays promotional banners.
 * If paymentType is provided, it shows payment method-specific ads.
 * If no paymentType is provided, it shows general ads.
 *
 * @param {object} props - Component props.
 * @param {string} props.paymentType - Optional. Type of payment method to display ads for.
 */
export default function AdBanner({ paymentType }) {
  const { ads } = useWhitelabelCopies();
  const currentLang = CurrentLang();

  if (!ads) {
    return null;
  }

  /**
   * Renders a promotional banner based on ad data.
   *
   * @param {object} ad - The advertisement data object.
   * @param {string} keyPrefix - Prefix for the React key to ensure uniqueness.
   * @returns {React.ReactElement|null} The rendered PromotionalBanner component or null if no banners available.
   */
  const renderBanner = (ad, keyPrefix) => {
    const { title, message, cta, banners } = ad[currentLang] || ad['es-MX'] || {};
    const { href, category, provider } = ad;
    if (!banners) return null;

    const { desktop, mobile } = banners;

    /**
     * Tracks when a user clicks on a promotional banner.
     *
     * @param {string} provider - The provider of the advertisement.
     * @param {string} category - The category of the advertisement.
     */
    const handleClick = (provider, category) => {
      trackEvent(`Touchpoint Clicked`, {
        Page: 'Confirmation',
        Provider: provider,
        Touchpoint: 'Promotional Banner',
        Category: category,
      });
    };

    return (
      <PromotionalBanner
        key={`${keyPrefix}-${title}-${href}`}
        title={title}
        message={message}
        banner={desktop}
        bannerMobile={mobile}
        href={href}
        linkText={cta}
        horizontal
        isExpandable={false}
        onClick={() => handleClick(provider, category)}
        newTab
      />
    );
  };

  if (paymentType) {
    if (ads.paymentMethods && ads.paymentMethods[paymentType]) {
      return <>{ads.paymentMethods[paymentType].map((ad) => renderBanner(ad, `${paymentType}`))}</>;
    }
    return null;
  }

  if (ads.general) {
    return <>{ads.general.map((ad) => renderBanner(ad, 'general'))}</>;
  }

  return null;
}

AdBanner.propTypes = {
  paymentType: PropTypes.string,
};

AdBanner.defaultProps = {
  paymentType: '',
};
