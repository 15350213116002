import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Spacing, Text } from '@reservamos/elements';
import Header from 'components/Header';
import LoadingScreen from 'components/LoadingScreen';
import PaymentStatusTitle from 'components/purchase/PaymentStatusTitle';
import HelpContact from 'components/purchase/HelpContact';
import OxxoPaymentInfo from 'components/purchase/OxxoPaymentInfo';
import PaycashPaymentInfo from 'components/purchase/PaycashPaymentInfo';
import PaypalPaymentInfo from 'components/purchase/PaypalPaymentInfo';
import PurchasePricing from 'components/purchase/PurchasePricing';
import TicketPassengers from 'components/purchase/TicketPassengers';
import PromotionalModal from 'components/PromotionalModal';
import AdBanner from 'components/AdBanner';
import CashPaymentInfo from '../CashPaymentInfo';
import BrandLogo from '../../BrandLogo';
import Recomendations from '../Recomendations';
import TransferPaymentTicket from '../TransferPaymentTicket';
import OpenTicketMessage from '../OpenTicketMessage';
import 'styles/components/purchase/PurchasesLayout';
import BankDetails from '../BankDetails';
import EstimatedAt from '../EstimatedAt/EstimatedAt';
import useReactNativeMessage from '../../../hooks/useReactNativeMessage';
import PixPaymentInfo from '../PixPaymentInfo';
import EfectyPaymentInfo from '../EfectyPaymentInfo';
import NequiPaymentInfo from '../NequiPaymentInfo';
import PurchaseLayout from '../../../ui/layouts/PurchaseLayout';
import wayIsOpenTicket from '../../../utils/wayIsOpenTicket';
import parsePurchaseStatus from '../../../utils/parsePurchaseStatus';
import EvaluateButton from '../../../ui/atoms/EvaluateButton';
import DotersPointsSummary from '../../../ui/atoms/DotersPointsSummary';
import DotersBanner from '../../../ui/atoms/DotersBanner';
import DownloadTicketsContainer from '../DownloadTicketsContainer';
import EvertecPaymentDetails from '../EvertecPaymentDetails';
import EvertecPaymentInfo from '../EvertecPaymentInfo';
import ModalItinerary from '../TicketItinerary/ModalItinerary';

/**
 * PurchaseComplete component handles the display and logic for the purchase completion process.
 * It manages different payment statuses, tracks purchases, and displays relevant information
 * based on the payment type and status.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.isFetching - Indicates if the purchase data is being fetched.
 * @param {Function} props.getPurchase - Function to fetch the purchase data.
 * @param {boolean} props.loaded - Indicates if the purchase data has been loaded.
 * @param {string} props.paymentType - The type of payment used.
 * @param {string} props.paymentProvider - The provider of the payment.
 * @param {string} props.purchaseStatus - The status of the purchase.
 * @param {string} props.token - The token associated with the purchase.
 * @param {boolean} props.trackPurchase - Indicates if the purchase should be tracked.
 * @param {Function} props.trackPurchaseHandler - Function to handle tracking the purchase.
 * @param {string} props.transferPaymentState - The state of the transfer payment.
 * @param {string} props.paymentEngine - The engine used for the payment.
 * @param {Function} props.pollPurchaseComplete - Function to poll the purchase completion status.
 * @param {string} props.paymentStatus - The status of the payment.
 * @param {Object} props.user - The user object.
 * @param {string} props.departsTicketPdfUrl - URL for the departure ticket PDF.
 * @param {string} props.returnTicketPdfUrl - URL for the return ticket PDF.
 * @param {boolean} props.isExchange - Indicates if the purchase is an exchange.
 * @param {Object} props.purchase - The purchase object.
 * @param {boolean} props.isOpenTicket - Indicates if the ticket is open.
 * @param {string} props.email - The email associated with the purchase.
 * @param {string} props.estimatedCompletedAt - The estimated completion time.
 * @param {string} props.departsTransporterKey - The key for the departure transporter.
 * @param {string} props.returnsTransporterKey - The key for the return transporter.
 * @returns {JSX.Element} The rendered component.
 */
const PurchaseComplete = ({
  isFetching,
  getPurchase,
  loaded,
  paymentType,
  paymentProvider,
  purchaseStatus,
  token,
  trackPurchase,
  trackPurchaseHandler,
  transferPaymentState,
  paymentEngine,
  pollPurchaseComplete,
  paymentStatus,
  user,
  departsTicketPdfUrl,
  returnTicketPdfUrl,
  isExchange,
  purchase,
  isOpenTicket,
  email,
  estimatedCompletedAt,
  departsTransporterKey,
  returnsTransporterKey,
}) => {
  const {
    features,
    env: environment,
    copies: { provider },
  } = useSelector((state) => state.whitelabelConfig);
  const history = useHistory();
  const { t } = useTranslation('purchase');
  const [isTracked, setIsTracked] = useState(!trackPurchase);
  const RNMessage = useReactNativeMessage();
  const isEvertecPayment = paymentEngine === 'evertec';
  const isEvertecPending = paymentStatus === 'attempt' && isEvertecPayment;
  const isPixPayment = paymentProvider === 'pix';
  const isPixPending = purchaseStatus === 'attempt' && isPixPayment;

  useEffect(() => {
    if (!loaded) getPurchase(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paymentStatus === 'chargeback' || purchaseStatus === 'canceled') {
      history.push(`/payment/${token}/canceled`);
    }
    if (
      (loaded && purchaseStatus === 'pending' && paymentStatus === 'charged') ||
      isEvertecPending ||
      isPixPending
    ) {
      pollPurchaseComplete(token, isPixPayment);
    }
    // when purchase complete remove lastPurchase token from storage
    // so no longer needed to call unlock seats
    if (loaded && purchaseStatus === 'completed' && paymentStatus === 'charged') {
      window.sessionStorage?.removeItem('lastPurchaseToken');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, loaded, purchaseStatus, paymentStatus, pollPurchaseComplete]);

  useEffect(() => {
    if (loaded && !isTracked) {
      setIsTracked(true);
      trackPurchaseHandler();
    }
  }, [isTracked, loaded, trackPurchaseHandler]);

  // Only for Mobile app webview
  useEffect(() => {
    // Verify if payment has succeed
    if (purchaseStatus === 'completed' && paymentStatus === 'charged') {
      RNMessage.post({ success: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseStatus, paymentStatus]);

  // Only for Mobile app webview
  useEffect(() => {
    // Verify if payment has succeed
    if (paymentType === 'paypal' && purchaseStatus === 'attempt') {
      RNMessage.post({ showPaypalPayment: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType, purchaseStatus]);

  const appendTokenToUrl = (url, token) => {
    const separator = url.includes('?') ? '&' : '?';
    return `${url}${separator}token=${token}`;
  };

  // TODO: Check if is necessary the nex condition
  //   if (purchaseStatus === 'chargeback' || isFetching || (trackPurchase && !isTracked)) {
  if (purchaseStatus === 'chargeback' || isFetching || trackPurchase) return <LoadingScreen />;

  const isPending = purchaseStatus === 'pending';
  const isAttempt = purchaseStatus === 'attempt';
  const isCompleted = purchaseStatus === 'completed';

  const showOxxoPayment = paymentType === 'store' && isPending && paymentEngine === 'conekta';
  const showPaycashPayment = paymentType === 'store' && isPending && paymentEngine === 'paycash';
  const showCashPayment = paymentType === 'store' && isPending && paymentEngine === 'kushki';
  const showPaypalPayment =
    (paymentType === 'paypal' || paymentType === 'third_party') && isAttempt;
  const showCardPayment =
    paymentType === 'credit_card' || (!showOxxoPayment && !showPaypalPayment && !showCashPayment);
  const showTransferDetails = paymentType === 'transfer' && transferPaymentState === 'OK';
  const showPixPaymentDetails = isPixPayment && !isCompleted;
  const isEfectyPayment = paymentProvider === 'efecty';
  const isNequiPayment = paymentProvider === 'efecty';
  const showEfectyPaymentDetails = isEfectyPayment && !isCompleted;
  const showNequiPaymentDetails = isNequiPayment && !isCompleted;
  const tenantUsesRemoteTickets = features.REMOTE_TICKETS;
  const showSendingTickets = !isCompleted && Boolean(!estimatedCompletedAt) && !isAttempt;
  const canDotersGenerate = Boolean(purchase.walletAccrualPoints || purchase.walletPointsUsed);
  const pendingStatus = Boolean(purchaseStatus === 'attempt' || paymentStatus === 'pending');
  const { isBulkTicket } = purchase;

  return (
    <div className="l-complete">
      <Header isFixed>
        <BrandLogo />
      </Header>

      <PaymentStatusTitle />
      <PurchaseLayout
        sidebar={
          <Spacing size="XL" vertical>
            {isBulkTicket && (
              <ModalItinerary
                text={t('title.check_trip_itinerary')}
                modalTrigger={<Button text={t('button.view_itinerary')} color="primary" />}
              />
            )}
            {!isBulkTicket && <TicketPassengers />}
            <Spacing vertical>
              <Text size="L" weight="bold">
                {t(isExchange ? 'label.exchange_details' : 'label.purchase_details')}
              </Text>
              <PurchasePricing />
            </Spacing>
            {/* TODO: delete TransferPaymentTicket when backend fixes bank info for Brasilia */}
            {showTransferDetails && <TransferPaymentTicket />}
            {isEvertecPayment && <EvertecPaymentDetails />}
            <BankDetails />
          </Spacing>
        }
      >
        <Spacing vertical>
          <AdBanner paymentType={paymentType} />

          {isCompleted && (
            <>
              <DownloadTicketsContainer
                hrefDeparture={departsTicketPdfUrl}
                hrefReturn={returnTicketPdfUrl}
                instructions={t('ticket_instructions_digital', { context: environment.brand })}
                isDepartureOpen={wayIsOpenTicket(purchase.departs)}
                isReturnOpen={wayIsOpenTicket(purchase.returns)}
                status={parsePurchaseStatus(purchaseStatus, paymentType)}
                isOpenTicket={isOpenTicket}
                displayDownloadTicketsButton={!tenantUsesRemoteTickets}
                email={email}
                departsTransporterKey={departsTransporterKey}
                returnsTransporterKey={returnsTransporterKey}
                token={token}
              />
              <div>
                <EvaluateButton />
              </div>
              {/* {(isOpenTicket || isHybridTrip) && <ExchangeCTA />} TODO: Removes comment after the mixed ticket branch gets merged */}
            </>
          )}
          {Boolean(estimatedCompletedAt) && (
            <EstimatedAt at={estimatedCompletedAt} reference={token} />
          )}
          {showSendingTickets && (
            <Spacing vertical size="XS">
              <Text>{t('you_tickets_will_be_ready')}</Text>
              <Text>
                {t('purchase_reference')} <b>{token}</b>
              </Text>
            </Spacing>
          )}
          {showCashPayment && <CashPaymentInfo />}
          {showOxxoPayment && <OxxoPaymentInfo />}
          {showPaycashPayment && <PaycashPaymentInfo />}
          {showPaypalPayment && <PaypalPaymentInfo />}
          {showPixPaymentDetails && <PixPaymentInfo />}
          {showEfectyPaymentDetails && <EfectyPaymentInfo />}
          {showNequiPaymentDetails && <NequiPaymentInfo />}
          {isEvertecPending && <EvertecPaymentInfo />}
          {isPending && showCardPayment && (
            <Spacing vertical size="S">
              <Text size="L" weight="bold">
                {t('why_should_I_wait')}
              </Text>
              <Text>{t('you_tickets_will_be_ready', { context: 'explanation' })}</Text>
            </Spacing>
          )}
          {isPending && features.SHOW_RECOMENDATIONS && <Recomendations />}

          <AdBanner />

          {canDotersGenerate ? (
            <DotersPointsSummary
              dotersUsed={purchase.walletPointsUsed}
              dotersEarned={purchase.walletAccrualPoints}
              pendingStatus={pendingStatus}
            />
          ) : (
            <DotersBanner show={!user && !features.DOTERS_AUTOMATIC_ENROLL} />
          )}
          <HelpContact
            email={provider.email}
            phones={provider.phones || []}
            invoiceUrl={
              features.INVOICE_URL_TOKEN
                ? appendTokenToUrl(provider.invoiceUrl, token)
                : provider.invoiceUrl
            }
            whatsapp={provider.whatsapp || []}
          />
          <OpenTicketMessage isLoggedIn={Boolean(user)} />
        </Spacing>
      </PurchaseLayout>
      <PromotionalModal page="confirmation" />
    </div>
  );
};

PurchaseComplete.propTypes = {
  getPurchase: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  paymentType: PropTypes.string,
  paymentProvider: PropTypes.string,
  purchaseStatus: PropTypes.string,
  token: PropTypes.string.isRequired,
  trackPurchase: PropTypes.bool.isRequired,
  trackPurchaseHandler: PropTypes.func.isRequired,
  transferPaymentState: PropTypes.string,
  paymentEngine: PropTypes.string,
  pollPurchaseComplete: PropTypes.func.isRequired,
  paymentStatus: PropTypes.string,
  departsTicketPdfUrl: PropTypes.string,
  returnTicketPdfUrl: PropTypes.string,
  user: PropTypes.object,
  isExchange: PropTypes.bool,
  purchase: PropTypes.object,
  isOpenTicket: PropTypes.bool.isRequired,
  email: PropTypes.string,
  estimatedCompletedAt: PropTypes.string,
  departsTransporterKey: PropTypes.string,
  returnsTransporterKey: PropTypes.string,
};

PurchaseComplete.defaultProps = {
  transferPaymentState: null,
  paymentEngine: null,
  departsTicketPdfUrl: '',
  returnTicketPdfUrl: '',
};

export default PurchaseComplete;
